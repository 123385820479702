<template>
  <div class="h-100">
    <v-row class="ma-0 mt-4 blocks-list h-100">
      <div class="h-100 w-100">
        <Loader v-if="fetching" />
        <grid-list
        v-else
        :headers="headers"
        :items="items"
        :fetching="false"
        :paginationVal="false">
        </grid-list>
      </div>
    </v-row>
    <v-container fluid v-if="addEquipment">
      <Modal persistent content-class="new-user-form" :modal="addEquipment" scrollable>
        <AddEquipment @close-modal="addEquipment = false" />
      </Modal>
    </v-container>
    <v-container fluid v-if="addShotSettings">
      <Modal persistent content-class="new-user-form" :modal="addShotSettings" scrollable>
        <AddShotSettings @close-modal="addShotSettings = false" />
      </Modal>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash';
import GridList from '@/components/organizations/GridList';
import Loader from '@/components/common/Loader';
import BtnActions from '@/components/sweepBlocks/BtnActions';
import Modal from '@/components/common/Modal';
import AddEquipment from '@/components/sweepBlocks/AddEquipment';
import AddShotSettings from '@/components/sweepBlocks/AddShotSettings';

export default {
  name: 'SweepBlockDetails',
  components: {
    GridList,
    Loader,
    Modal,
    AddShotSettings,
    AddEquipment,
  },
  data() {
    return {
      addEquipment: false,
      addShotSettings: false,
    };
  },
  computed: {
    headers() {
      const $this = this;
      const items = [$this.nameColumn(), $this.btnActionsColumn()];
      return items;
    },
    items() {
      const data = [{ id: 1, name: 'Equipment' }, { id: 2, name: 'Shot Settings' }];
      return _.map(data, (business) => ({
        id: business.id,
        name: business.name,
        duration: `${business.duration} min`,
      }));
    },
  },
  methods: {
    async openModal(data) {
      if (data.value === 'edit') {
        if (data.type === 'Equipment') this.addEquipment = true;
        if (data.type === 'Shot Settings') this.addShotSettings = true;
      }
      return false;
    },
    nameColumn() {
      return {
        headerName: 'Name',
        field: 'name',
        sortable: true,
        filter: true,
        cellClass: 'notranslate text-wrap',
      };
    },
    btnActionsColumn() {
      const $this = this;
      return {
        headerName: '',
        cellClass: 'd-flex justify-end',
        field: 'id',
        width: 20,
        cellRendererFramework: BtnActions,
        cellRendererParams: (data) => ({
          options: [
            {
              value: 'edit',
              title: 'Edit',
              color: 'charcoal',
              type: data.data.name,
            },
          ],
          clicked(params) {
            $this.openModal(params);
            return false;
          },
        }),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.blocks-list {
  height: 207px !important;
  overflow: auto;
}
</style>
