<template >
  <v-card flat>
    <div class="px-5">
      <v-row class="my-0">
        <v-col class="d-flex align-center pb-1" cols=10>
          <span class="text-capitalize mr-1 modal-header-title"> Update Creative Tier </span>
        </v-col>
        <v-col class="d-flex justify-end pb-1">
          <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="px-5">
      <v-form class="register-form" ref="form" @submit="submitForm">
        <v-row class="d-flex align-center ma-0 pt-2">
          <v-col cols="12" md="12" class="pa-0 pt-2">
            <BaseInput
              class="text-input"
              solo
              v-model.trim="tier.tier"
              inputLabel="Tier"
              placeholder
              name="tier"
              disabled
              hide-details
            />
          </v-col>
          <v-col cols="12" md="12" class="pa-0">
            <BaseInput
              class="text-input"
              solo
              :value="tier.skillset.name"
              inputLabel="Skill Set"
              placeholder
              name="skillset"
              disabled
              hide-details
            />
          </v-col>
          <v-col cols="12" md="12" class="pa-0">
            <BaseInput
              class="text-input mb-4 rate-field"
              solo
              v-model="tier.rate"
              inputLabel="Rate/hr ($)"
              placeholder
              name="rate"
              required
              :status="errors('rate').length ? 'error': 'normal'"
              :error-messages="errors('rate')"
              @input="$v.tier.rate.$touch()"
              @blur="$v.tier.rate.$touch()"
            />
          </v-col>
        </v-row>
        <!-- <v-divider></v-divider> -->
        <v-row class="ma-0 pb-2">
          <v-col class="d-flex justify-end px-0">
            <v-btn
              :loading="loading" class="btn-purple"
              type="submit" @click="submitForm">
              Update
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import {
  numeric, required, maxLength, minLength, minValue,
} from 'vuelidate/lib/validators';
import BaseInput from '@/components/common/BaseInput';

export default {
  components: {
    BaseInput,
  },
  props: {
    tier: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.tier[field].$dirty) return errors;
        switch (field) {
          case 'rate':
            if (!this.$v.tier.rate.required) { errors.push('Please provide Rate'); }
            if (!this.$v.tier.rate.numeric) { errors.push('Please provide valid Rate'); }
            if (!this.$v.tier.rate.minLength) { errors.push('Please provide valid Rate'); }
            if (!this.$v.tier.rate.maxLength) { errors.push('Please provide valid Rate'); }
            if (!this.$v.tier.rate.minValue) { errors.push('Please provide valid Rate'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  data() {
    return ({
      loading: false,
    });
  },
  methods: {
    ...mapActions('admin', ['updatePayTier']),

    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const result = await this.updatePayTier(this.tier);
        if (result.success) {
          this.$emit('close-modal', true);
        }
        this.loading = false;
      }
    },
  },
  validations: {
    tier: {
      rate: {
        required,
        numeric,
        minLength: minLength(1),
        maxLength: maxLength(4),
        minValue: minValue(1),
      },
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .input-field-label {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.02px;
  color: $charcoalBlack;
}
::v-deep .text-input > .input-field > .v-input__control > .v-input__slot {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  border: solid 1px $silver;
  background-color: #ffffff;
  input {
    text-transform: capitalize;
  }
}
.rate-field {
  ::v-deep .input-field > .v-input__control > .v-input__slot {
    padding-left: 5px;
  }
}
::v-deep .v-text-field.v-text-field--solo .v-input__control {
  min-height: 40px !important;
  height: auto !important;
}
</style>
