<template>
  <span class="d-flex align-center table-btn-actions">
    <div class="d-flex justify-end card-body w-100">
      <v-menu bottom left offset-y offset-x content-class="uploaded-content">
        <template v-slot:activator="{ on }">
          <v-btn dark icon height="auto" width="20" v-on="on" class="menu-btn">
            <v-icon color="black" dense>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list style="min-width: 200px;">
          <v-list-item class="pa-0" v-for="(option, i) in params.options" :key="i">
            <v-list-item-title>
              <v-btn
                text
                class="w-100 px-2 py-1"
                :color="option.color"
                @click="openModal(option)"
              >
                {{ option.title }}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </span>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  methods: {
    openModal(id) {
      this.params.clicked(id);
    },
  },
});
</script>

<style lang="scss" scoped>
.v-list-item {
  padding: 10px 15px !important;
  border-bottom: 1px solid #e0e0e0;
  &:last-child {
    border: none;
  }
}
.v-card {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: $neutral6;
}
.v-card__title {
  font-family: $fontFamily1;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.24px;
  color: $neutral1;
  padding: 10px;
  line-height: 1rem;
}
.uploaded-content {
  .v-list-item {
    min-height: 0 !important;
    padding: 0;
  }
}
.button-content {
  justify-content: left;
}
.v-list {
  border-radius: 0;
  padding: 0;
}
.menu {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 5px;
  z-index: 7;
}
.menu-btn {
  width: 10px;
  margin-right: 5px;
}
.v-menu__content {
  border-radius: 0;
}
.v-list-item {
  min-height: auto;
  padding: 0 5px;
  .v-btn {
    height: 30px;
  }
  ::v-deep {
    .v-btn__content {
      font-size: 14px;
      font-family: $fontFamily1;
      justify-content: flex-start;
      width: auto;
      .download-icon {
        padding-right: 5px;
      }
    }
    .v-btn {
      padding: 5px;
    }
  }
}
</style>
