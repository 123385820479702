<template>
  <div class="h-100">
    <DeleteSweepBlock ref="DeleteSweepBlockModel"/>
    <v-row class="ma-0 align-center justify-space-between">
      <div
        :style="
          `
      max-width: ${$vuetify.breakpoint.smAndDown ? '100%' : '270px'},
      order: ${$vuetify.breakpoint.smAndDown ? 1 : 0}
          `
        "
      >
        <BaseInput
          solo
          hide-details
          v-model="search"
          clearable
          @click:clear="search === ''"
          placeholder="Search by name"
          prepend-inner-icon="search"
          class="mt-7 search-box"
        />
      </div>
      <v-btn class="add-button btn-purple mt-7" @click="openModal({ value: 'edit' })"
        ><v-icon class="mr-2">mdi-plus</v-icon> Add Sweep Block</v-btn
      >
    </v-row>
    <v-row class="ma-0 mt-4 blocks-list h-100">
      <div class="h-100 w-100">
        <Loader v-if="fetching" />
        <grid-list
          v-else
          :headers="headers"
          :items="items"
          :fetching="fetching"
          :search="search"
        >
        </grid-list>
      </div>
    </v-row>
     <v-container
      fluid
      v-if="addSweepBlockModal"
    >
      <Modal
        persistent
        content-class="new-user-form"
        :modal="addSweepBlockModal"
        scrollable
      >
        <AddSweepBlock
        :sweepBlockData="sweepBlockData"
        @close-modal="addSweepBlockModal = false" />
      </Modal>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import BaseInput from '@/components/common/BaseInput';
import GridList from '@/components/organizations/GridList';
import Loader from '@/components/common/Loader';
import BtnActions from '@/components/sweepBlocks/BtnActions';
import Modal from '@/components/common/Modal';
import AddSweepBlock from '@/components/sweepBlocks/AddSweepBlock';
import DeleteSweepBlock from '@/components/sweepBlocks/DeleteSweepBlock';

export default {
  name: 'SweepBlocksList',
  components: {
    BaseInput,
    GridList,
    Loader,
    Modal,
    AddSweepBlock,
    DeleteSweepBlock,
  },
  data() {
    return {
      fetching: true,
      search: '',
      addSweepBlockModal: false,
      deleteSweepBlockModal: false,
      sweepBlockData: {},
    };
  },
  computed: {
    ...mapGetters('sweepBlocks', ['sweepBlocks']),
    headers() {
      const $this = this;
      const items = [$this.nameColumn(), $this.durationColumn(), $this.btnActionsColumn()];
      return items;
    },
    items() {
      const data = this.sweepBlocks;
      return _.map(data, (block) => ({
        id: block.id,
        name: block.name,
        duration: `${block.durationInMinutes} min`,
      }));
    },
  },
  methods: {
    ...mapActions('sweepBlocks', ['getEquipmentsList', 'getShotSettings', 'getSweepBlocks', 'removeSweepBlock']),
    async openModal(data) {
      if (data.value === 'edit') {
        this.sweepBlockData = await this.sweepBlocks.find((block) => block.id === data.id);
        this.addSweepBlockModal = true;
      }
      if (data.value === 'delete') {
        const confirmModal = this.$refs.DeleteSweepBlockModel;
        const { confirm } = await confirmModal.open();
        if (!confirm) {
          return false;
        }
        await this.removeSweepBlock(data.id);
      }
      return false;
    },
    nameColumn() {
      return {
        headerName: 'Sweep Block',
        field: 'name',
        sortable: true,
        filter: true,
        cellClass: 'notranslate text-wrap',
      };
    },
    durationColumn() {
      return {
        headerName: 'Duration',
        field: 'duration',
        sortable: true,
        filter: true,
        cellClass: 'notranslate text-wrap',
      };
    },
    btnActionsColumn() {
      const $this = this;
      return {
        headerName: '',
        cellClass: 'd-flex justify-end',
        field: 'id',
        width: 20,
        cellRendererFramework: BtnActions,
        cellRendererParams: (data) => ({
          options: [
            {
              value: 'edit',
              title: 'Edit Sweep Block',
              color: 'charcoal',
              id: data.data.id,
            },
            {
              value: 'delete',
              title: 'Delete Sweep Block',
              color: 'red',
              id: data.data.id,
            },
          ],
          clicked(params) {
            $this.openModal(params);
            return false;
          },
        }),
      };
    },
  },
  async mounted() {
    await this.getEquipmentsList();
    await this.getShotSettings();
    this.fetching = true;
    await this.getSweepBlocks();
    this.fetching = false;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .search-box .v-input {
  height: 40px !important;
  .v-input__control {
    min-height: 40px !important;
    .v-input__slot {
      min-height: 40px !important;
      box-shadow: none !important;
    }
  }
}
.blocks-list {
  height: calc(100vh - 322px) !important;
  overflow: auto;
}
</style>
