<template>
  <div class="main-container">
    <PageTitle />
    <v-row class="ma-0 mt-4">
      <v-tabs v-model="activeTab" >
        <v-tab v-for="(tab, index) in tabs" :key="index" @click="updatePathQuery(tab.value)">
          {{ tab.name }}
        </v-tab>
        <v-tabs-items v-model="activeTab">
          <v-tab-item
            v-for="(tab, index) in tabs"
            :key="index"
            :transition="false"
            :reverse-transition="false"
          >
            <SweepBlocksList v-if="activeTab===0" />
            <SweepBlockDetails v-if="activeTab===1" />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-row>
  </div>
</template>

<script>
import PageTitle from '@/components/common/PageTitle';
import SweepBlocksList from '@/components/sweepBlocks/SweepBlocksList';
import SweepBlockDetails from '@/components/sweepBlocks/SweepBlockDetails';

export default {
  name: 'SweepBlocks',
  components: {
    PageTitle,
    SweepBlocksList,
    SweepBlockDetails,
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          name: 'Sweep Blocks',
          value: 'sweepBlocks',
        },
        {
          name: 'Sweep Block Details',
          value: 'sweepBlockDetails',
        },
      ],
    };
  },
  methods: {
    updatePathQuery(tab) {
      this.$router.replace({ query: { ...this.$route.query, activeTab: tab } });
    },
  },
  async mounted() {
    const { activeTab } = this.$route.query;
    if (activeTab) {
      this.tabs.map((tab, i) => {
        if (tab.value === activeTab) {
          this.activeTab = i;
        }
        return false;
      });
    }
  },
};
</script>
