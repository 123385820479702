<template>
  <v-form class="add-shotSettings-form">
    <v-container class="pa-0">
      <v-row class="ma-auto title-header">
        <v-col class="d-flex align-center py-0 pl-0" cols="10">
          <span class="text modal-header-title"> Sweep Block Shot Settings </span>
        </v-col>
        <v-col class="d-flex justify-end py-0 pr-0">
          <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
        </v-col>
      </v-row>
      <div class="main-div">
        <div class="label-div">
          <label class="font-label">Add Shot Setting</label>
          <v-row class="justify-between ma-0 align-center mt-1">
            <v-col
              class="pa-0"
              :class="{
                'errorState v-input--has-state error--text': shotErr,
              }"
            >
              <BaseInput
                :class="{
                  'errorState v-input--has-state error--text': shotErr,
                }"
                name="title"
                solo
                dense
                flat
                hide-details
                v-model.trim="title"
                @input="checkForValue"
                @blur="checkForValue"
              />
            </v-col>
            <v-btn class="add-btn ml-4" @click="addShotSettingsData()">Add</v-btn>
          </v-row>
          <span
            :class="{
              'errorState v-input--has-state error--text': shotErr,
            }"
          >
            {{ shotError }}
          </span>
        </div>
        <div class="capture-settings">
          <div class="font-h4 label-div">Shot Settings</div>
          <v-divider class="mx-5" />
          <v-row v-if="loading" class="ma-auto pa-5 justify-center"> Loading... </v-row>
          <div
          class="scrollable-div"
          v-else>
            <v-row
            class="ma-0 shotSettings-list-item"
            v-for="(item, i) in shotSettings" :key="item">
              <v-col class="pa-0">
                <span class="font-body"> {{ item.name }} </span>
              </v-col>
              <v-col class="d-flex justify-end py-0 pr-0">
                <v-icon
                  color="darken-1"
                  class="cursor-pointer"
                  @click="deleteShotSettings(i)"
                  >mdi-close</v-icon
                >
              </v-col>
            </v-row>
            <v-row v-if="shotSettings.length === 0"
            class="ma-auto pa-5 justify-center">No data available</v-row>
          </div>
        </div>
      </div>
      <v-card-actions>
        <v-btn text class="py-0" color="primaryGray1" @click="$emit('close-modal')">
          Cancel
        </v-btn>
        <v-btn class="py-0 submit btn-purple" @click="saveShotsettings()">
          Save
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseInput from '@/components/common/BaseInput';

export default {
  name: 'AddShotSettings',
  components: {
    BaseInput,
  },
  data() {
    return {
      title: '',
      shotError: '',
      shotErr: false,
      shotSettings: [],
      loading: true,
    };
  },
  methods: {
    ...mapActions('sweepBlocks', ['saveShotSettings', 'getShotSettings', 'removeShotSettings']),
    async saveShotsettings() {
      if (!this.shotErr) {
        await this.saveShotSettings({ shotSettings: this.shotSettings });
      }
      this.$emit('close-modal');
    },
    async checkForValue() {
      if (!this.title) {
        this.shotErr = true;
        this.shotError = 'Please provide shot settings';
      } else if (this.title.length > 160) {
        this.shotErr = true;
        this.shotError = 'Shot settings must be less than 160 characters length';
      } else {
        this.shotErr = false;
        this.shotError = '';
      }
    },
    async addShotSettingsData() {
      await this.checkForValue();
      if (!this.shotErr) {
        this.shotSettings.unshift({ name: this.title });
        this.title = '';
      }
    },
    async deleteShotSettings(i) {
      this.shotSettings.splice(i, 1);
    },
  },
  computed: {
    ...mapGetters('sweepBlocks', ['shotSettingsList']),
  },
  async mounted() {
    await this.getShotSettings();
    this.shotSettings = this.shotSettingsList;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.add-shotSettings-form {
  ::v-deep .errorState .v-input .v-input__control .v-input__slot {
    border: solid 1px #ff0808 !important;
  }
  .add-btn {
    background: #fff !important;
    border: 2px solid #41e0ba;
    border-radius: 1.5rem;
    box-shadow: none;
    width: 80px;
  }
  .main-div {
    .label-div {
      padding: 20px 20px 0px 20px;
    }
    .capture-settings {
      .v-divider {
        border-color: #E6E6E6;
      }
      .scrollable-div {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 330px;
        padding: 0px 20px 0px 20px;
      }
      .font-h4 {
        padding-bottom: 11px;
        margin-top: 10px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #B5B5B5;
      }
      .shotSettings-list-item {
        padding: 7px 0px;
        border-bottom: 1px solid #e6e6e6;
        .font-body {
          color: $charcoalBlack;
        }
      }
    }
  }
  .title-header {
    border-bottom: 1px solid #e0e0e0;
    padding: 16px 21px 13px 21px;
  }
}
.v-form {
  .v-card__actions {
    padding: 0;
    justify-content: flex-end;
    padding: 14px 27px 18px;
    .v-btn {
      border-radius: 6px;
      font-weight: 400;
      color: $secondary3;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      line-height: 24px;
      ::v-deep .v-btn__content {
        font-weight: 500;
        line-height: 19px;
        font-size: 16px;
      }
    }
    .submit {
      width: 112px;
      margin-left: 12px !important;
    }
  }
}
::v-deep .input-field {
  font-family: $fontFamily1;
  height: inherit;
}
::v-deep .v-text-field {
  margin-top: 0;
  padding-top: 0;
}
::v-deep .v-select__slot {
  font-family: $fontFamily1;
}
::v-deep .v-input {
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset;
  }
}
.upload-button {
  font-family: $fontFamily1;
  font-size: 12px;
  letter-spacing: -0.01px;
  color: $secondary1;
  span {
    cursor: pointer !important;
  }
}
::v-deep .normalState .v-input__slot {
  border: 1px solid #e0e0e0 !important;
}
::v-deep .nullState .v-input__slot {
  border: 1px solid #e0e0e0 !important;
}
::v-deep .v-input {
  .v-input__control {
    .v-select__selection,
    input {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #4f4f4f !important;
    }
  }
}
::v-deep .v-input__slot {
  min-height: 40px;
}
::v-deep .type-selection {
  margin-top: 6px;
  .v-input__slot {
    border: solid 1px #e6e8ed;
  }
}
::v-deep .type-selection.error--text .v-input__slot {
  border: solid 1px #ff0808;
}
</style>
