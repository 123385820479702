import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{staticClass:"add-shotSettings-form"},[_c(VContainer,{staticClass:"pa-0"},[_c(VRow,{staticClass:"ma-auto title-header"},[_c(VCol,{staticClass:"d-flex align-center py-0 pl-0",attrs:{"cols":"10"}},[_c('span',{staticClass:"text modal-header-title"},[_vm._v(" Sweep Block Shot Settings ")])]),_c(VCol,{staticClass:"d-flex justify-end py-0 pr-0"},[_c(VIcon,{attrs:{"color":"darken-1"},on:{"click":function($event){return _vm.$emit('close-modal')}}},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"main-div"},[_c('div',{staticClass:"label-div"},[_c('label',{staticClass:"font-label"},[_vm._v("Add Shot Setting")]),_c(VRow,{staticClass:"justify-between ma-0 align-center mt-1"},[_c(VCol,{staticClass:"pa-0",class:{
              'errorState v-input--has-state error--text': _vm.shotErr,
            }},[_c('BaseInput',{class:{
                'errorState v-input--has-state error--text': _vm.shotErr,
              },attrs:{"name":"title","solo":"","dense":"","flat":"","hide-details":""},on:{"input":_vm.checkForValue,"blur":_vm.checkForValue},model:{value:(_vm.title),callback:function ($$v) {_vm.title=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"title"}})],1),_c(VBtn,{staticClass:"add-btn ml-4",on:{"click":function($event){return _vm.addShotSettingsData()}}},[_vm._v("Add")])],1),_c('span',{class:{
            'errorState v-input--has-state error--text': _vm.shotErr,
          }},[_vm._v(" "+_vm._s(_vm.shotError)+" ")])],1),_c('div',{staticClass:"capture-settings"},[_c('div',{staticClass:"font-h4 label-div"},[_vm._v("Shot Settings")]),_c(VDivider,{staticClass:"mx-5"}),(_vm.loading)?_c(VRow,{staticClass:"ma-auto pa-5 justify-center"},[_vm._v(" Loading... ")]):_c('div',{staticClass:"scrollable-div"},[_vm._l((_vm.shotSettings),function(item,i){return _c(VRow,{key:item,staticClass:"ma-0 shotSettings-list-item"},[_c(VCol,{staticClass:"pa-0"},[_c('span',{staticClass:"font-body"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c(VCol,{staticClass:"d-flex justify-end py-0 pr-0"},[_c(VIcon,{staticClass:"cursor-pointer",attrs:{"color":"darken-1"},on:{"click":function($event){return _vm.deleteShotSettings(i)}}},[_vm._v("mdi-close")])],1)],1)}),(_vm.shotSettings.length === 0)?_c(VRow,{staticClass:"ma-auto pa-5 justify-center"},[_vm._v("No data available")]):_vm._e()],2)],1)]),_c(VCardActions,[_c(VBtn,{staticClass:"py-0",attrs:{"text":"","color":"primaryGray1"},on:{"click":function($event){return _vm.$emit('close-modal')}}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"py-0 submit btn-purple",on:{"click":function($event){return _vm.saveShotsettings()}}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }