import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"d-flex align-center table-btn-actions"},[_c('div',{staticClass:"d-flex justify-end card-body w-100"},[_c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","offset-x":"","content-class":"uploaded-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"menu-btn",attrs:{"dark":"","icon":"","height":"auto","width":"20"}},on),[_c(VIcon,{attrs:{"color":"black","dense":""}},[_vm._v("mdi-dots-horizontal")])],1)]}}])},[_c(VList,{staticStyle:{"min-width":"200px"}},_vm._l((_vm.params.options),function(option,i){return _c(VListItem,{key:i,staticClass:"pa-0"},[_c(VListItemTitle,[_c(VBtn,{staticClass:"w-100 px-2 py-1",attrs:{"text":"","color":option.color},on:{"click":function($event){return _vm.openModal(option)}}},[_vm._v(" "+_vm._s(option.title)+" ")])],1)],1)}),1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }