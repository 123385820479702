<template>
  <div class="main-container h-100">
    <div class="pb-10"> <PageTitle /> </div>
    <Loader v-if="fetching" />
    <base-list
      v-else
      :headers="headers"
      :items="items"
      :fetching="fetching"
      @onEditClick="$emit('onEditClick')"
    >
    </base-list>
    <v-container
      fluid
      v-if="showForm"
    >
      <Modal
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
        content-class="new-user-form"
        :modal="showForm"
        scrollable
      >
        <CreativeTierForm
          :tier="selectedTier"
          @close-modal="closeForm"
        />
      </Modal>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import BtnCellRenderer from '@/components/projects/ProjectTeam/BtnCellRenderer';
import Modal from '@/components/common/Modal';
import CreativeTierForm from '@/components/dashboard/screens/Admin/Forms/CreativeTierForm';
import Loader from '@/components/common/Loader';
import PageTitle from '@/components/common/PageTitle';
import BaseList from '../components/admin/common/BaseList';

export default {
  components: {
    BaseList,
    CreativeTierForm,
    Modal,
    Loader,
    PageTitle,
  },
  computed: {
    ...mapGetters('admin', ['creativePayTiers']),

    headers() {
      const $this = this;
      return [
        {
          header: 'Tier',
          field: 'tier',
          cellClass: 'text-capitalize',
        }, 'skill set', 'rate', $this.editColumn(),
      ];
    },
    items() {
      return _.map(this.creativePayTiers, (tier) => ({
        id: tier.id,
        tier: tier.tier,
        'skill set': tier.skillset.name,
        rate: tier.skillset.name === 'Talent' ? `$${tier.rate}` : `$${tier.rate}/hr`,
      }));
    },
  },
  data() {
    return ({
      fetching: false,
      showForm: false,
      selectedTier: {},
    });
  },
  methods: {
    ...mapActions('admin', ['getPayTiers']),
    editClick(id) {
      this.selectedTier = Object.assign({}, _.find(this.creativePayTiers, { id }));
      this.showForm = true;
    },
    editColumn() {
      const $this = this;
      return {
        headerName: '',
        field: 'id',
        cellClass: 'action pa-2 d-flex justify-center',
        cellRendererFramework: BtnCellRenderer,
        cellRendererParams: {
          image: 'edit',
          clicked(params) {
            $this.editClick(params.value);
            return false;
          },
        },
        width: 60,
      };
    },
    async closeForm(success = false) {
      this.showForm = false;
      this.selectedTier = {};
      if (success) {
        this.fetching = true;
        await this.getPayTiers();
        this.fetching = false;
      }
    },
  },
  async mounted() {
    this.fetching = true;
    await this.getPayTiers();
    this.fetching = false;
  },
};
</script>
