import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{style:({ zIndex: _vm.options.zIndex }),attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"card-container",staticStyle:{"border-radius":"unset !important"}},[_c(VRow,{staticClass:"ma-auto title-header"},[_c(VCol,{staticClass:"d-flex align-center py-0 pl-0",attrs:{"cols":"10"}},[_c('span',{staticClass:"text modal-header-title"},[_vm._v(" Delete Sweep Block ")])]),_c(VCol,{staticClass:"d-flex justify-end py-0 pr-0"},[_c(VIcon,{staticClass:"cursor-pointer",attrs:{"color":"darken-1"},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pa-4"},[_c('div',{staticClass:"body-text"},[_vm._v("Are you sure you want to permanently delete this sweep block? Sweeps will remain unchanged. "),_c('div',{staticClass:"pt-5"},[_vm._v(" Once completed, the action can not be undone.")])])]),_c(VCardActions,{staticClass:"pa-4 pt-0"},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","depressed":"","color":"primaryGray1"},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"complete-order-button btn-red",attrs:{"depressed":""},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v("Delete")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }